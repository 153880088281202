<template>
    <div class="login-container">
        
        <div class="login-form">
            <img class="logo" :src="logo" alt="BeWellPlan Dashboard" />
            <div class="message">You have been logged out.</div>
            <div v-if="!$auth.loading">
                <el-button v-if="!$auth.isAuthenticated" type="primary" style="width:100%;" @click="login">Login</el-button>
                <el-button v-if="$auth.isAuthenticated " type="primary" style="width:100%;" @click="logout">Logout</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import logo from '@/assets/logo.svg';

    export default {
        name: 'login',
        data() {
            return {
                logo,
            };
        },
        methods: {

            // Log the user in
            login() {
                this.$auth.loginWithRedirect();
            },
            logout() {
                this.$auth.logout({
                    returnTo: `${process.env.VUE_APP_AUTH0_BASE_URL}/logout`                
                });
            },
        },
    };
</script>

<style rel="stylesheet/scss" lang="scss">

    .login-container {
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        background-color: $grey;

        .login-form {
            margin-top: 200px;
            position: relative;
            border-radius: 30px;
            width: 500px;
            height: 200px;

            padding: 0 35px;
            overflow: hidden;
            background-color: #304156;

            .message {
                color: white;
                text-align: center;
                margin-bottom: 20px;
            }

            .logo {
                width: 250px;
                margin: 30px auto;
                display: block;
            }
        }
    }
</style>
